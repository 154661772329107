import React, { useState } from 'react';
import { Menu, Badge, Box, Typography, Avatar, List, ListItem, ListItemAvatar, ListItemText, Divider } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';

const notifications = [
    {
        id: 1,
        title: "Your order is placed",
        description: "Amet minim mollit non deserunt ullamco est sit aliqua.",
        time: "3 min ago",
        avatar: "/path/to/avatar1.jpg"
    },
    {
        id: 2,
        title: "Congratulations Darlene 🎉",
        description: "Won the monthly best seller badge",
        time: "3 min ago",
        avatar: "/path/to/avatar2.jpg"
    },
    {
        id: 3,
        title: "Revised Order 👋",
        description: "Won the monthly best seller badge",
        time: "3 min ago",
        avatar: "/path/to/avatar3.jpg"
    },
    {
        id: 4,
        title: "Brooklyn Simmons",
        description: "Added you to Top Secret Project group...",
        time: "3 min ago",
        avatar: "/path/to/avatar4.jpg"
    }
];

const NotificationsMenu = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Box
                sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 48,
                    height: 48,
                    borderRadius: '50%',
                    backgroundColor: '#f1f5f9',
                }}
                onClick={handleClick}
            >
                <Badge
                    badgeContent={2}
                    color="error"
                    sx={{
                        '& .MuiBadge-badge': {
                            backgroundColor: '#FF3D57',
                            color: 'white',
                        }
                    }}
                >
                    <NotificationsIcon sx={{ color: '#374151' }} />
                </Badge>
            </Box>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        width: '300px',
                    },
                }}
            >
                <Box sx={{ px: 2, py: 1, display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="h6" sx={{ color: '#374151', fontWeight: 500 }}>Notifications</Typography>
                    <Typography variant="body2" color="primary" sx={{ cursor: 'pointer' }}>View all</Typography>
                </Box>
                <Divider />
                <List>
                    {notifications.map((notification) => (
                        <ListItem key={notification.id} alignItems="flex-start">
                            <ListItemAvatar>
                                <Avatar alt={notification.title} src={notification.avatar} />
                            </ListItemAvatar>
                            <ListItemText
                                primary={notification.title}
                                secondary={
                                    <>
                                        <Typography
                                            component="span"
                                            variant="body2"
                                            color="textPrimary"
                                        >
                                            {notification.description}
                                        </Typography>
                                        <br />
                                        <Typography
                                            component="span"
                                            variant="caption"
                                            color="textSecondary"
                                        >
                                            {notification.time}
                                        </Typography>
                                    </>
                                }
                            />
                        </ListItem>
                    ))}
                </List>
            </Menu>
        </>
    );
};

export default NotificationsMenu;
