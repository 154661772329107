import { createTheme } from "@mui/material/styles";

export const myTheme = createTheme({
    palette: {
        mode: "light",
        primary: {
            main: "#7367f0",
        },
        secondary: {
            main: "#1D1C1B",
        },
    },
    components: {

        MuiListItemButton: {
            styleOverrides: {
                root: {
                    backgroundColor: "#a19af5",
                    /*  borderRadius: "25px",*/
                    "&:hover": { backgroundColor: "#a19af5" },
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                root: {
                    fontWeight: "bold",
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    backgroundColor: "#ececec",
                    fontWeight: "bold",
                },
                root: {
                    padding: '4px',
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                head: {
                    borderBottom: 1,
                    borderColor: "#1D1C1B",
                },
                root: {
                    "&:nth-of-type(odd)": {
                        backgroundColor: "#a19af5",
                    },
                },
            },
        },
    },
});
