import React, { createContext, useContext, useState } from 'react';
import { LoginData } from "../../services/loginData";
import { toast } from "react-toastify";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    const login = (requestData, onSuccess) => {
        new LoginData().getLogin(requestData).then((res) => {
            localStorage.setItem('accessToken', res.data.access_token);
            setUser(requestData.username);
            localStorage.setItem('username',requestData.username);
            if (typeof onSuccess === 'function') {
                onSuccess(); // Assurez-vous que onSuccess est bien une fonction
            }
            toast.success("Login successful!");
        }).catch((error) => {
            toast.error("Login failed: " + error.message);
        });
    };

    const logout = () => {
        setUser(null);
        localStorage.removeItem('accessToken');
        localStorage.removeItem('username')

    };

    const value = {
        user,
        login,
        logout
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
