import DashboardIcon from '@mui/icons-material/Dashboard';
import CurrencyExchangeRoundedIcon from '@mui/icons-material/CurrencyExchangeRounded';
import EuroSymbolRoundedIcon from '@mui/icons-material/EuroSymbolRounded';

export const nav =[
    {
        path : "/dashboard",
        name: "Dashboard",
        icon: < DashboardIcon />
    },
    {
        path : "/expense",
        name: "Expense",
        icon: <CurrencyExchangeRoundedIcon />
    },{
        path : "/income",
        name: "Income",
        icon: <EuroSymbolRoundedIcon  />
    }

] 
