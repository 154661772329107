import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import reportWebVitals from './reportWebVitals';
import {ThemeProvider} from '@mui/material/styles';
import {myTheme} from "./theme/theme";
import {AuthProvider} from "./components/reducer/authreducer";



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ThemeProvider theme={myTheme}>
        <React.StrictMode>
            <AuthProvider>
                <App/>
            </AuthProvider>
        </React.StrictMode>
    </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
