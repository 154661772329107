import React, { lazy, Suspense, useState } from "react";
import Sidebar from './pages/sidebar';
import { CssBaseline, Box } from '@mui/material';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from "react-toastify";
import './App.css';
import PrivateRoute from "./components/Route/PrivateRoute";
import {drawerWidth} from "./pages/sidebar";

function App() {
    const [sidebarOpen, setSidebarOpen] = useState(true);

    const handleDrawerOpen = () => {
        setSidebarOpen(true);
    };

    const handleDrawerClose = () => {
        setSidebarOpen(false);
    };

    const Expense = lazy(()=> import("./pages/expense"));
    const Income = lazy(()=> import("./pages/income"));
    const DashboardPage = lazy(()=> import("./pages/dashboardPage"));
    const Login = lazy(()=> import("./pages/login"));
    const Profile =lazy(()=> import("./components/reusable/user/ProfilePage"));


    // Créez un nouveau composant pour utiliser useLocation
    const LayoutWithSidebar = () => {
        let location = useLocation(); // Obtient la route actuelle

        // Ne pas afficher la Sidebar sur la page de connexion
        const showSidebar = location.pathname !== "/";

        return (
            <>
                {showSidebar && <Sidebar open={sidebarOpen} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose} />}
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        backgroundColor: '#f1f5f9',
                        paddingLeft: sidebarOpen ? `${drawerWidth}px` : 0,
                        transition: 'padding-left 0.2s',
                        minHeight: '100vh',
                    }}
                >
                    <Suspense fallback={<div>Loading...</div>}>
                        <Routes>
                            <Route path ="/" element={<Login/>}/>
                            <Route path="expense" element={<PrivateRoute component={Expense} />} />
                            <Route path="profile" element={<PrivateRoute component={Profile} />} />
                            <Route path="income" element={<PrivateRoute component={Income} />} />
                            <Route path="dashboard" element={<PrivateRoute component={DashboardPage} />} />
                            {/* Ajoutez d'autres routes si nécessaire */}
                        </Routes>
                    </Suspense>
                </Box>
            </>
        );
    };

    return (
        <>
            <ToastContainer />
            <BrowserRouter>
                <CssBaseline />
                <LayoutWithSidebar />
            </BrowserRouter>
        </>
    );
}

export default App;
