// PrivateRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import {useAuth} from "../reducer/authreducer";



const PrivateRoute = ({ component: Component, ...rest }) => {
    const {user} = useAuth();
    const isLoggedIn = user; // Remplacez ceci par votre logique d'authentification

    return isLoggedIn ? <Component {...rest} /> : <Navigate to="/" replace />;
};

export default PrivateRoute;

