import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { nav } from "../data/nav";
import { NavLink } from 'react-router-dom';
import {  Button } from '@mui/material';
import { useMatch, useResolvedPath } from 'react-router-dom';
import {useAuth} from "../components/reducer/authreducer";
import { useNavigate } from "react-router-dom";
import MobileLogo from "../asset/logowin.png";
import UserMenu from "../components/reusable/UserMenu";
import NotificationsMenu from "../components/reusable/NotificationsMenu";



export const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        alignItems: 'center',
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: open ? `${drawerWidth}px` : 0,
    }),
);

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

const CustomListItemText = styled(ListItemText)(({ theme }) => ({
    fontFamily: " Arial", // Choisissez votre police
    fontSize: "60px",
    fontWeight: "bold",
    letterSpacing: "1px", // Ajoutez "px" pour spécifier les pixels
    color: "black", // Choisissez votre couleur de texte
    lineHeight: "1.5"

}));

const NavItemColor = ({ item }) => {
    let resolved = useResolvedPath(item.path);
    let match = useMatch({ path: resolved.pathname, end: true });

    return (
        <ListItem disablePadding>
            <ListItemButton
                component={NavLink}
                to={item.path}
                style={{ backgroundColor: match ? '#eceff2' : 'white' }}
            >
                <ListItemIcon>
                    {item.icon}
                </ListItemIcon>
                <CustomListItemText primary={item.name} />
            </ListItemButton>
        </ListItem>
    );
};

const Sidebar = ({ open, handleDrawerOpen, handleDrawerClose }) => {
    const {logout,user} = useAuth();
    const theme = useTheme();
    const navigate = useNavigate();
    const handleLogout = () => {
        logout(() => navigate('/')); // Naviguer vers la racine après la déconnexion
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center'  }}>
            <CssBaseline />
            <AppBar position="fixed" open={open} sx={{ backgroundColor: 'white' }}>
                <Toolbar>
                    <IconButton
                        color="black"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{ mr: 2, ...(open && { display: 'none' }) }}
                    >
                        <MenuIcon />
                    </IconButton>
                    {(user !==null) ?(
                        <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
                            <NotificationsMenu />
                            <UserMenu user={user} handleLogout={handleLogout} />
                        </Box>
                    ) :(
                        <Button color="inherit" onClick={ ()=> navigate("/")} sx={{ color: 'black' }}>
                            Login
                        </Button>
                    ) }

                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader>
                    <img src={MobileLogo} alt="Logo" style={{height: 80}}/>
                    <h4>Finanzmanager</h4>
                    <IconButton onClick={handleDrawerClose} sx={{
                        color: 'black', // Définit la couleur par défaut
                        '&:hover, &:focus, &:active': {
                            color: 'black', // Maintient la couleur noire pour les états hover, focus et active
                        }
                    }}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon/> : <ChevronRightIcon/>}
                    </IconButton>
                </DrawerHeader>
                <Divider/>
                <List>
                    {nav.map((data) => {
                        return (
                            <NavItemColor key={data.name} item={data}/>
                        );
                    })}
                </List>
            </Drawer>
            <Main open={open}>
                <DrawerHeader />
            </Main>
        </Box>
    );
};

export default Sidebar;


